import React from "react";
import { graphql } from "gatsby";

import { SEO } from "components";
import { HomeCloser, HomeHero, HomeIntro, HomeLinkCards } from "views";

function IndexPage({ data }) {
  const PAGE = data.page.data;
  return (
    <>
      <SEO
        title={PAGE.seo_title && PAGE.seo_title.text}
        description={PAGE.seo_description && PAGE.seo_description.text}
        imageOpenGraph={PAGE.seo_image && PAGE.seo_image.url}
        imageTwitter={PAGE.seo_image && PAGE.seo_image.url}
        imageAlt={PAGE.seo_image && PAGE.seo_image.alt}
      />

      <HomeHero {...PAGE} />
      <HomeIntro {...PAGE} />
      <HomeLinkCards {...PAGE} />
      <HomeCloser {...PAGE} />
    </>
  );
}

export const query = graphql`
  {
    page: prismicHomepage {
      data {
        hero_image {
          fluid(maxWidth: 2100) {
            ...GatsbyPrismicImageFluid
          }
          alt
          url
        }
        headline {
          text
        }
        introductory_title {
          text
        }
        introductory_description {
          html
        }
        introductory_button_label
        introductory_button_link {
          url
        }
        secondary_button_label
        secondary_button_link {
          url
        }
        link_cards {
          image {
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
            alt
            url
          }
          title {
            text
          }
          body {
            html
          }
          button_label
          button_link {
            url
          }
        }
        closing_title {
          text
        }
        closing_description {
          html
        }
        closing_button_label
        closing_button_link {
          url
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        seo_image {
          url
          alt
        }
      }
    }
  }
`;

export default IndexPage;
